import React from "react"
import "./a-propos.css"
import PageHeader from "../components/PageHeader/PageHeader"
import Citation from "../components/Citation/Citation"
import PageFooter from "../components/PageFooter/PageFooter"
import SectionL from "../components/Section/SectionL"
import SectionR from "../components/SectionR/SectionR"
import TextWithBackgroundText from "../components/TextWithBackgroundText/TextWithBackgroundText"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const APropos = () => {
  const { sanityAboutmepage } = useStaticQuery(query)
  const { Citation1, Citation2, Text1, Text2, Text3, CatchPhrase, Header } =
    sanityAboutmepage
  const image1 = getImage(sanityAboutmepage.Photo1.asset)
  const image2 = getImage(sanityAboutmepage.Photo2.asset)
  const image3 = getImage(sanityAboutmepage.Photo3.asset)

  return (
    <div style={{ paddingTop: "100vh" }}>
      <Seo title="À propos" />
      <PageHeader
        titre={"À propos"}
        sousTitre={"Accueil • À propos"}
        media={Header}
      />
      <div className="wrapperAPropos">
        <div className={"cadreAPropos"}>
          <h1 className={"titreAPropos"}>Félix Brossard</h1>
          <h2 className={"descriptionAPropos"}>
            Vidéaste et photographe professionnel
          </h2>
        </div>
        <SectionR description={Text1} photos={image1} />
      </div>
      <SectionL description={Text2} photos={image2} />
      <div className={"citationAPropos"}>
        <Citation text={Citation1} theme={"dark"} />
      </div>
      <SectionR description={Text3} photos={image3} />
      <div className={"citationAPropos"}>
        <Citation text={Citation2} theme={"dark"} />
      </div>
      <TextWithBackgroundText
        text={CatchPhrase._rawText}
        backgroundText={CatchPhrase.backgroundText}
        buttonText={"Discutons de votre projet"}
        buttonOnClick={() => navigate("/contact")}
      />

      <PageFooter />
    </div>
  )
}

const query = graphql`
  query {
    sanityAboutmepage{
      Citation1
        Citation2
        Text1
        Text2
        Text3
        Header {
            ...HeaderImage
            ...HeaderYoutubeVideo
        }
        Photo1 {
            asset {
                gatsbyImageData(width: 800, placeholder: BLURRED)
            }
        }
        Photo2 {
            asset {
                gatsbyImageData(width: 800, placeholder: BLURRED)
            }
        }
        Photo3 {
            asset {
                gatsbyImageData(width: 800, placeholder: BLURRED)
            }
        }
        CatchPhrase {
            _rawText
            backgroundText
        }
    }
  }
`

export const Head = () => <Seo title="À propos" />

export default APropos
